<template>
    <div class="app-comments-wrap" :class="{ 'with-pagination': withPagination }">
        <div class="app-comments">
            <app-loader v-if="loading"></app-loader>

            <div class="header">
                <div class="heading">Events</div>

                <div class="input" @click="focus">
                    <div class="magnifier"><i></i></div>

                    <input
                        v-model="value"
                        ref="input"
                        type="text"
                        placeholder="Search"

                        @input="onInput($event.target.value)"
                        @keyup.enter="onEnter"
                    >
                </div>

                <div class="order" :class="{ desc }" @click="toggleOrder"></div>
            </div>

            <div class="comment" v-for="comment in comments" :key="comment.uuid">
                <div class="info">
                    <div class="name">{{ comment.author }}</div>
                    <div class="date">{{ comment.date }}</div>
                </div>

                <div class="text">{{ comment.message }}</div>
            </div>
        </div>

        <app-pagination
            v-if="pagination.total && withPagination"

            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        />
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        customer_uuid:  {               required: true },
        delay:          { type: Number,  default: 1000 }, // The number of milliseconds to wait
        withPagination: { type: Boolean, default: true },
    },

    components: {
        appLoader,
        appPagination,
    },

    data() {
        return {
            debounce: null,

            loading: false,

            value: '',
            previous: '',
            order: 'desc',
            sort: 'TimestampNanos',

            comments: [],

            pagination: {
                page: 1,
                total: 0,
                limit: 5,
                range: 2,
            },
        }
    },

    computed: {
        desc() {
            return this.order == 'desc'
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.customer_uuid) {
                this.search()
            }
        },

        onInput() {
            if (this.delay) {
                clearTimeout(this.debounce)

                this.debounce = setTimeout(() => {
                    this.search()
                }, this.delay)
            } else {
                this.search()
            }
        },

        onEnter() {
            if (this.delay) {
                clearTimeout(this.debounce)
            }

            this.search()
        },

        onPageChange(page) {
            if (this.delay) {
                clearTimeout(this.debounce)
            }

            this.search(page)
        },

        toggleOrder() {
            if (this.order == 'de') {
                this.order = 'desc'
            } else {
                this.order = 'de'
            }

            if (this.delay) {
                clearTimeout(this.debounce)
            }

            this.search()
        },

        search(page) {
            this.loading = true

            let params = {
                SPID: this.$store.getters.current_spid,
                CustomerUUID: this.customer_uuid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.sort,
                'SearchOptions.SortDesc':   this.order === 'desc' ? 1 : 0,
            }

            const word = this.value.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': this.value !== this.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.previous = this.value
            }

            this.$store.dispatch('api_event/FindEventsPaginated', params)
                .then(({ PageInfo, Events }) => {

                    this.comments = Events.map(event => ({
                        uuid: event.UUID,
                        author: `${event.AgentName}`,
                        date: formatDateNano(event.TimestampNanos),
                        message: event.Message
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.comments = []

                    this.loading = false
                })
        },

        focus() {
            this.$refs.input.focus();
        },
    },

    watch: {
        customer_uuid() {
            if (this.customer_uuid) {
                if (this.delay) {
                    clearTimeout(this.debounce)
    
                    this.debounce = setTimeout(() => {
                        this.search()
                    }, this.delay)
                } else {
                    this.search()
                }
            }
        },
    },
}
</script>

<style lang="scss">
.app-comments-wrap {
    position: relative;

    .app-pagination {
        margin-top: 30px;
    }

    &.with-pagination {
        .app-loader {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
        }
    }
}

.app-comments {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: var(--color-component-bg-primary);
    box-shadow: var(--box-shadow-primary);
    border-radius: $border-radius-primary;

    .header {
        display: flex;
        align-items: center;
        height: 72px;

        .heading {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            color: var(--color-text-h2);
        }

        .input {
            position: relative;
            display: flex;
            width: 100%;

            .magnifier {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 100%;

                i {
                    width: 26px;
                    height: 26px;

                    color: var(--color-icon-search);

                    @include icon-before($icon-search, 26px);
                }
            }

            input {
                width: 100%;
                padding-left: 48px;
                padding-right: 16px;
                font-size: 18px;
                line-height: 24px;
                color: var(--color-text-default);

                &::-webkit-input-placeholder {
                    color: var(--color-input-placeholder);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &::-moz-placeholder {
                    color: var(--color-input-placeholder);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:-ms-input-placeholder {
                    color: var(--color-input-placeholder);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:-moz-placeholder {
                    color: var(--color-input-placeholder);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:focus {
                    &::-webkit-input-placeholder {
                        opacity: 0;
                    }

                    &::-moz-placeholder {
                        opacity: 0;
                    }

                    &:-ms-input-placeholder {
                        opacity: 0;
                    }

                    &:-moz-placeholder {
                        opacity: 0;
                    }
                }
            }
        }

            .order {
                flex-shrink: 0;
                width: 24px;
                height: 24px;
                cursor: pointer;

                color: var(--color-icon-calm);

                @include icon-before($icon-arrow-bottom);

                &.desc {
                    transform: rotateX(180deg);
                }
            }
    }

    .comment {
        padding: 16px 24px;
        border-bottom: 1px solid var(--color-divider);

        .info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .name {
                font-weight: bold;
                color: var(--color-comments-primary);
            }

            .date {
                font-size: 14px;
                color: var(--color-comments-secondary);
            }

        }

        .text {
            color: var(--color-comments-primary);
            word-break: break-word;
        }
    }

    .comment {
        &:last-child {
            border-bottom: none;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-comments {
        font-size: 14px;
        line-height: 24px;
        padding: 16px 8px;

        .comment {
            padding: 15px 8px;
        }

        .header {
            height: 40px;

            .input {
                margin-left: 8px;
                
                input {
                    font-size: 16px;
                    padding-left: 30px
                }

                .magnifier {
                    width: 32px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-comments {
        .header {
            height: 32px;

            .heading {
                font-size: 24px;
            }
        }

        .comment {
            margin: 8px 0 0;
            padding: 8px 0;
        }
    }
}
</style>