<template>
    <div class="app-customer-event-log">
        <h2 class="heading">Customer Event Log</h2>

        <app-textarea
            v-model="message"

            label="Write a note..."

            :required="true"
            :error="errors.fields.message"
        />

        <button class="btn btn-primary" @click="post">Submit</button>

        <app-loader v-if="loading"></app-loader>
    </div>
</template>

<script>
import appTextarea from '@/components/app-textarea'
import appLoader from '@/components/app-loader'

export default {
    components: {
        appTextarea,
        appLoader,
    },

    props: {
        customer_uuid:  { required: true },
    },

    data() {
        return {
            loading: false,

            message: '',

            errors: {
                fields: {
                    message: '',
                },
            },
        }
    },

    methods: {
        reset() {
            this.message = ''
        },

        validation() {
            let errors = 0;
            this.errors.fields = {}

            const message = this.message.trim()

            if (!message.length) {
                this.errors.fields.message = 'Your message is invalid'
                errors++;
            }

            return !errors
        },

        post() {
            if (this.validation()) {
                this.loading = true

                const payload = {
                    SPID: this.$store.getters.current_spid,
                    CustomerUUID: this.customer_uuid,
                    Message: this.message.trim(),
                }

                this.$store.dispatch('api_event/AddEvent', payload)
                    .then(() => {
                        this.message = ''

                        this.$emit('refresh-comments-list')

                        this.loading = false
                    })
                    .catch(error => {
                        console.log(error)

                        this.loading = false
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.app-customer-event-log {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: var(--color-component-bg-primary);
    box-shadow: var(--box-shadow-secondary);
    border-radius: $border-radius-primary;

    .heading {
        margin-bottom: 24px;
    }

    .app-textarea {
        margin-bottom: 24px;
    }

    .btn {
        margin: 0 auto;
    }
}

@media (max-width: $tablet-size) {
    .app-customer-event-log {
        padding: 24px 8px;

        .heading {
            padding: 0 8px;
        }

        .app-textarea {
            height: 168px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-customer-event-log {
        padding: 16px 8px 24px;

        .heading {
            margin-bottom: 16px;
            padding: 0;
        }

        .app-textarea {
            height: 129px;
        }

        .btn {
            max-width: calc(100% - 32px);
        }
    }
}
</style>